<!-- 事件时间轴 -->
<template>
  <div class="timeline">
    <a-timeline>
      <a-timeline-item
        v-for="(item, index) in steps"
        :key="index"
        :color="item.color"
      >
        <div style="color: #7d8da6; font-size: 0.07rem">
          {{ item.time }}
        </div>
        <div style="color: #2d3138; font-size: 0.07rem; margin-top: 1%">
          {{ item.title }}
        </div>
      </a-timeline-item>
    </a-timeline>
    <div
      style="
        display: flex;
        height: 20px;
        align-items: center;
        color: #2a5cff;
        font-size: 0.08rem;
        cursor: pointer;
      "
      @click="sortSteps"
    >
      <i class="el-icon-sort"> </i>
      <div style="width: 78px; margin-left: 4px">时间轴排序</div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: ["steps"],
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    sortSteps() {
      // 根据 isAscending 变量决定排序顺序
      this.steps.sort((a, b) => {
        const timeA = this.parseTimeToMinutes(a.time);
        const timeB = this.parseTimeToMinutes(b.time);
        // 如果 isAscending 为 true，则升序排序；否则降序排序
        return this.isAscending ? timeA - timeB : timeB - timeA;
      });

      // 切换排序顺序
      this.isAscending = !this.isAscending;

      // 重置当前步骤到第一个
      this.current = 0;

      console.log(this.steps);
    },

    // 将时间字符串转换为分钟数
    parseTimeToMinutes(timeString) {
      const dateTime = new Date(timeString);
      const startOfDay = new Date(
        dateTime.getUTCFullYear(),
        dateTime.getUTCMonth(),
        dateTime.getUTCDate()
      );
      const diffInMs = dateTime - startOfDay;
      const diffInMinutes = Math.floor(diffInMs / (1000 * 60)); // 将毫秒转换为分钟
      return diffInMinutes;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.timeline {
  margin-top: 2%;
  display: flex;
  /deep/.ant-timeline {
    width: 100%;
  }
}
</style>